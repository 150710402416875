import { Box, MenuItem, Select, type SxProps } from '@mui/material'
import { body1Typography, colors, spacing } from '@orus.eu/pharaoh'
import { memo } from 'react'

export type SelectFieldProps = {
  value?: string | null
  values: readonly string[]
  /**
   * Optional text that is displayed when nothing is selected. Provide it when
   * there is a state where nothing is selected to hint the user about how to use the select field.
   */
  placeholder?: string
  labels: { [key: string]: string }
  onChange: (newValue: string) => void
  disabled?: boolean
  sx?: SxProps
}

/**
 * @deprecated Please use Dropdown component in Pharaoh
 */
export const SelectField = memo(function SelectField(props: SelectFieldProps): JSX.Element {
  return (
    <Select
      disabled={props.disabled}
      fullWidth={true}
      labelId="subscription-activity-type"
      id="subscription-activity-type-select"
      value={props.value || ''}
      onChange={(event) => props.onChange(event.target.value)}
      IconComponent={SelectFieldArrow}
      sx={
        props.placeholder && !props.value
          ? {
              '&:after': {
                ...body1Typography,
                pointerEvents: 'none',
                color: colors.gray[600],
                position: 'absolute',
                left: spacing[50],
                content: `"${props.placeholder}"`,
              },
              ...props.sx,
            }
          : props.sx
      }
    >
      {props.values.map((value) => (
        <MenuItem key={value} value={value}>
          {props.labels[value]}
        </MenuItem>
      ))}
    </Select>
  )
})

const SelectFieldArrow = memo(function SelectFieldArrow({ className }: { className: string }): JSX.Element {
  return (
    <Box className={className} sx={{ marginRight: '10px', marginTop: '-5px' }}>
      <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.4323 1.96614L6.68234 6.43801C6.53859 6.60051 6.35109 6.66926 6.16672 6.66926C5.98234 6.66926 5.79547 6.60089 5.65109 6.4642L0.901092 1.96614C0.600154 1.67864 0.587654 1.20364 0.871717 0.90364C1.15703 0.600515 1.63359 0.591141 1.93234 0.875516L6.16672 4.88489L10.4011 0.87239C10.6999 0.588203 11.1745 0.598953 11.4617 0.901687C11.7448 1.20364 11.7323 1.67864 11.4323 1.96614Z"
          fill="#0C0C0E"
        />
      </svg>
    </Box>
  )
})
